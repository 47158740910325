import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "src/components/loading"
import { getCampaignById } from "src/services/marketing-service"
import { BLOCK_TYPE, CampaignInfoType, LayerType } from "./type"
import VideoBase from "src/components/video-base"
import BannerSliderSection from "src/components/banner-slider-section"
import ImgBuyerSide from "src/components/img-buyer-side-custom"
import TabsListSection from "src/components/tab-list"
import TextSection from "src/components/text-section"
import ProductSession from "src/components/product-list/ProductSection"
import { SystemDiscount, VoucherType } from "src/components/product-list/props"
import { SoctripPromotionControllerApi } from "@soctrip/axios-promotion-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function ReviewPage() {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [campaignInfo, setCampaignInfo] = useState<
    CampaignInfoType | undefined
  >(undefined)
  const [layers, setLayers] = useState<LayerType[]>([])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [systemDiscount, setSystemDiscount] = useState<SystemDiscount>()
  const navigate = useNavigate()

  const fetchData = () => {
    getCampaignById(id || "")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          setCampaignInfo({
            id: res.data.data?.id || "",
            title: res.data.data.title || "",
          })
          if (res?.data?.data?.layers?.length) {
            setLayers(res.data.data.layers)
            const hasProductListBlock = res?.data?.data?.layers.some(
              (layer: LayerType) =>
                layer.blocks.length > 0 &&
                layer.blocks.some((block) => block.code === "PRODUCT_LIST")
            )
            if (hasProductListBlock) {
              getSystemVoucherPublic()
            } else {
              setIsLoading(false)
            }
          } else navigate("/not-found")
        } else navigate("/not-found")
      })
      .catch(() => navigate("/not-found"))
  }

  const getSystemVoucherPublic = () => {
    new SoctripPromotionControllerApi(configHeader(SERVICE.PROMOTION))
      .findAllValidVouchers(0, 100)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          let valueCompare = 0
          let result: SystemDiscount | undefined = undefined
          const shipVouchers: VoucherType[] = []
          let shipVoucherMin: VoucherType | undefined = undefined
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            const currentTime = new Date().getTime()
            const discountRange = (i?.voucher_discount_range ?? [])?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (range: any) => range.discount_percent || 0
            )
            const currentValue = Math.max(...discountRange)
            if (
              currentTime >= new Date(i?.start_date).getTime() &&
              currentTime <= new Date(i?.end_date).getTime()
            ) {
              if (
                i?.soctrip_voucher_type?.code === "FREE_SHIP" &&
                i?.voucher_discount_range?.length &&
                i?.voucher_discount_range[0]?.discount_type === "PERCENTAGE"
              ) {
                shipVouchers.push(i)
              }
              if (currentValue > valueCompare) {
                valueCompare = currentValue
                result = { discountPercent: currentValue }
              }
            }
          })
          // get free ship
          shipVouchers.forEach((i) => {
            if (!shipVoucherMin) {
              shipVoucherMin = i
            }
            if (
              shipVoucherMin?.voucher_discount_range?.length &&
              i.voucher_discount_range?.length &&
              ((shipVoucherMin?.voucher_discount_range[0]
                ?.min_order_price as number) ||
                0 > i.voucher_discount_range[0].min_order_price ||
                0)
            ) {
              shipVoucherMin = i
            }
          })
          setSystemDiscount(result)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    console.log(campaignInfo)
    fetchData()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (isLoading) return <Loading />
  return (
    <div className="my-3 flex h-full w-full flex-col items-center justify-center gap-3">
      {layers.map((i) => (
        <>
          {/** Update layout later */}
          <div id={i.id} key={i.id + 10} className="flex w-full gap-[24px]">
            {i.blocks.map((item) => (
              <>
                {/* IMAGE */}
                {item.code === BLOCK_TYPE.IMAGE ? (
                  <ImgBuyerSide
                    url={item.media[0]?.media_desktop_id}
                    mobileUrl={item.media[0]?.media_mobile_id}
                    isMobile={isMobile}
                  />
                ) : null}
                {/* SLIDE */}
                {item.code === BLOCK_TYPE.SLIDE ? (
                  <BannerSliderSection data={item.media} isMobile={isMobile} />
                ) : null}
                {/* VIDEO */}
                {item.code === BLOCK_TYPE.VIDEO ? (
                  <div key={i.id}>
                    <VideoBase key={i.id} data={{ ...i }} />
                  </div>
                ) : null}
                {/* TEXT */}
                {item.code === BLOCK_TYPE.TEXT ? (
                  <div key={i.id}>
                    <TextSection content={item.text} />
                  </div>
                ) : null}
                {item.code === BLOCK_TYPE.TAB ? (
                  <TabsListSection tabs={item.media} />
                ) : null}
                {item.code === BLOCK_TYPE.PRODUCT_LIST ? (
                  <ProductSession {...item} systemDiscount={systemDiscount} />
                ) : null}
              </>
            ))}
          </div>
        </>
      ))}
    </div>
  )
}
