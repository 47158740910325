import React from "react"
import Empty from "../../assets/images/empty.png"

interface PropsType {
  message?: string
  className?: string
}

export default function EmptyPage(props: PropsType) {
  return (
    <div
      className={`flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2 ${
        props.className ? props.className : ""
      }`}
    >
      <img
        src={Empty}
        alt="empty image"
        className="object-cover object-center"
      />
      {/* <div className="text-24 font-semibold text-blue-light-400">
        No results found
      </div> */}
      <div className="text-16 font-medium text-gray-500">{props?.message}</div>
    </div>
  )
}
