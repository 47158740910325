import React from "react"
import { Product } from "../props"
import { MediaType } from "../enum"
import ImgCustom from "src/components/img-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { convertCurrency } from "src/utils/convert-currency"
import CartIcon from "src/assets/images/cart-icon.svg"

export default function ProductCard(props: Product) {
  const {
    id,
    name,
    price,
    imageUrl,
    videoId,
    mediaType,
    autoPlay,
    discount,
    isFlashDeal,
    systemDiscount,
  } = props
  const { t } = useTranslation()

  const goToProductDetail = () => {
    return `/${id}`
  }

  const onAddToCard = () => {
    console.log("Add product: ", id)
  }

  const renderMedia = () => {
    if (mediaType === MediaType.VIDEO) {
      return (
        <video
          src={videoId}
          muted
          className="h-full w-full object-cover"
          autoPlay={autoPlay}
        />
      )
    } else
      return (
        <ImgCustom
          className="max-h-[180px] min-h-[180px] w-full transform object-cover transition duration-[1500ms] hover:scale-110"
          url={imageUrl as string}
          alt="images"
        />
      )
  }

  const getSystemDiscount = () => {
    if (systemDiscount && systemDiscount?.discountPercent)
      return capitalizedFirst(
        t("system.system-voucher", {
          value: systemDiscount.discountPercent,
        })
      )
  }

  const renderSystemDiscount = () => {
    if (!systemDiscount?.discountPercent && !systemDiscount?.discountAmount)
      return null
    return (
      <div className="rounded-3 bg-blue-50 px-[8px] py-[4px] text-[12px] font-medium text-blue-600">
        <span>
          {systemDiscount.discountPercent
            ? getSystemDiscount()
            : convertCurrency(systemDiscount.discountAmount)}
        </span>
      </div>
    )
  }

  return (
    <div className="relative flex flex-col justify-center overflow-hidden rounded-[8px] border border-gray-200">
      <div className="h-full overflow-hidden rounded-tl-[8px] rounded-tr-[8px]">
        {renderMedia()}
      </div>
      {/* Discount tag */}
      {discount ? (
        <div className="absolute right-0 top-0 bg-orange-50 px-[8px] py-[4px] text-[14px] text-orange-600">
          {`-${discount}%`}
        </div>
      ) : null}
      <div className="flex h-full w-full flex-col gap-[10px] p-[16px]">
        <a className="text-[14px] font-medium" href={goToProductDetail()}>
          <div
            id="product_title"
            className="flex cursor-pointer text-[13px] font-medium leading-18 hover:text-blue-light-500  hover:underline sm:text-14"
          >
            {name}
          </div>
        </a>
        <div className="flex h-full flex-col justify-between gap-[12px]">
          <div className="flex w-full flex-wrap items-center gap-[4px]">
            {isFlashDeal ? (
              <div className="rounded-3 bg-orange-50 px-[8px] py-[4px] text-[12px] font-medium text-orange-600">
                {capitalizedFirst(t("product-details.flash-sale"))}
              </div>
            ) : null}
            {renderSystemDiscount()}
          </div>
          <div className="flex items-center justify-between truncate">
            <p className="truncate text-18 font-semibold leading-30 text-orange-dark-600 md:text-20">
              {convertCurrency(price)}
            </p>
            <img
              src={CartIcon}
              alt="Cart"
              className="h-[40px] cursor-pointer"
              onClick={onAddToCard}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
