import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { BannerItemType, BannerSliderType } from "./props"
import "./index.scss"
import { getImageUrl } from "src/services/file-service"

export default function BannerSliderSection(props: BannerSliderType) {
  const { data, className, isMobile } = props

  const getUrl = (desktopUrl: string, mobileUrl?: string) => {
    if (isMobile && mobileUrl) return getImageUrl(mobileUrl)
    return getImageUrl(desktopUrl)
  }

  const getBannerSlide = () => {
    const banners: BannerItemType[] = data.map((item) => ({
      id: item.media_desktop_id || item.media_mobile_id,
      desktopLinkId: item.media_desktop_id,
      mobileLinkId: item.media_mobile_id,
      isOpenNewTab: item.is_open_in_new_tab,
      navigateUrl:
        isMobile && item.media_mobile_id && item.mobile_link
          ? item.mobile_link
          : item.desktop_link,
    }))
    return banners
  }

  return (
    <div
      className={`banner-slider-container flex justify-center ${
        className ? className : ""
      }`}
    >
      <Swiper slidesPerView="auto" navigation={true} modules={[Navigation]}>
        {getBannerSlide().map((item) => (
          <SwiperSlide key={item.id}>
            <a
              href={item.navigateUrl}
              target={item.isOpenNewTab ? "_blank" : "_self"}
              rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
            >
              <img
                src={getUrl(item.desktopLinkId, item.mobileLinkId)}
                alt="banner"
                className="h-full w-full cursor-pointer object-fill"
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
