import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { initialState } from "./type"
export const shoppingCartSlice = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    updateTotalCart: (state, action: PayloadAction<number>) => {
      state.totalCart = action.payload
    },
  },
})

export const {
  updateTotalCart,
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
