import React from "react"
import { TextType } from "./props"
import "./index.scss"

export default function TextSection(props: TextType) {
  const { content } = props

  return (
    <div
      className="text-content w-full"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
