import React, { useEffect, useState } from "react"
import {
  IMAGE_DUMMY,
  TEXT_SECTION_DATA,
  TEXT_SECTION_DATA_2,
  voucherDataDummy,
} from "./data"
import { VoucherBaseType } from "src/components/voucher-base/type"
import VoucherBase from "src/components/voucher-base"
import TextSection from "src/components/text-section"
import ImgBuyerSide from "src/components/img-buyer-side-custom"

export default function DemoPage() {
  const [vouchers, setVouchers] = useState<VoucherBaseType[]>([])
  const [imgDelay, setImg] = useState<string>("")

  useEffect(() => {
    setTimeout(() => {
      setImg(IMAGE_DUMMY[1].url)
    }, 3000)
  })

  useEffect(() => {
    const voucherResult = [] as VoucherBaseType[]
    voucherDataDummy.forEach((i) => voucherResult.push({ data: i }))
    setVouchers(voucherResult)
  }, [])

  return (
    <div className="flex w-full flex-col items-center justify-center">
      {/* <div className="w-full max-w-[960px] ">
        <ProductSession />
      </div> */}

      {/* TAB SECTION DEMO */}
      {/* <div className="mt-[48px] w-full max-w-[960px]">
        <TabsListSection tabs={TAB_LIST} />
      </div> */}
      {/* VOUCHER DEMO */}
      <div className="mt-[48px] w-full max-w-[960px]">
        <div className="flex grid grid-cols-2 items-center gap-2">
          {vouchers.map((i) => (
            <VoucherBase key={i.data.id} {...i} />
          ))}
        </div>
      </div>

      {/* TEXT SECTION DEMO */}
      <div className="mt-[48px] w-full max-w-[960px]">
        <TextSection content={TEXT_SECTION_DATA_2} />
      </div>
      <div className="mt-[48px] w-full max-w-[960px]">
        <TextSection content={TEXT_SECTION_DATA} />
      </div>
      {/* IMAGE SECTION DEMO */}
      <div className="mt-[48px] w-full max-w-[960px]">
        <ImgBuyerSide url={IMAGE_DUMMY[0].url} alt="image1" />
        <ImgBuyerSide
          url={imgDelay}
          alt="image3"
          className="mt-2 object-fill object-center"
        />
      </div>

      {/* SLIDER SECTION DEMO */}
      {/* <div className="mt-[48px] w-full max-w-[960px]">
        <BannerSliderSection banners={BANNER_SLIDER_DUMMY} />
      </div> */}
    </div>
  )
}
