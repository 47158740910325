import React, { useState } from "react"
import { TabItemAttributeType, TabListType } from "./props"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { Navigation } from "swiper/modules"
import TabItem from "./components/TabItem"
import "./index.scss"
export default function TabsListSection(props: TabListType) {
  const { tabs, onClickTab } = props
  const [selectedTab, setSelectedTab] = useState<string>("")

  const handleTabClick = (tabId: string, refId?: string) => {
    setSelectedTab(tabId)
    if (refId) scrollToLayer(refId)
    if (onClickTab) onClickTab(tabId)
  }

  const getTabData = (): TabItemAttributeType[] => {
    return tabs.map((item) => ({
      tabData: {
        id: item.media_desktop_id || item.media_mobile_id,
        defaultImg: item.media_desktop_id,
        selectedImg: item.media_mobile_id || item.media_desktop_id,
      },
    }))
  }

  const scrollToLayer = (id: string): void => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  return (
    <div id="tab-list-section" className="flex w-full justify-start">
      <Swiper
        slidesPerView="auto"
        spaceBetween={16}
        navigation={true}
        modules={[Navigation]}
        className="!px-[16px]"
        breakpoints={{
          765: {
            spaceBetween: 32,
          },
        }}
      >
        {getTabData().map((item) => (
          <SwiperSlide
            key={item.tabData.id}
            className="!flex !w-auto items-center"
          >
            <TabItem
              {...item}
              selectedTabId={selectedTab}
              onTabClick={(tabId) =>
                handleTabClick(tabId, item.tabData.referenceId)
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
