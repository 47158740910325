import React, { useMemo, useRef, useState } from "react"
import { ImgCustomProps } from "./props"
import "./index.scss"
import imageNotFound from "../../assets/images/image-not-found.png"
import avatarDefault from "src/assets/images/avatar-default.webp"
import bannerDefault from "src/assets/images/bg.jpg"
import shopDefault from "src/assets/images/defaultshop.webp"
import voucherDefault from "src/assets/images/white-log.svg"
// const getBase64StringFromDataURL = (dataURL: string) =>
//   dataURL.replace("data:", "").replace(/^.+,/, "")

const ImgCustom = (props: ImgCustomProps) => {
  const { alt, className, url, isAvatar, isBanner, isShop, isVoucher } = props
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const imgRef = useRef<HTMLImageElement>(null)

  const getStyleImage = useMemo(() => {
    return className ? className : ""
  }, [className])

  const handleLoadError = () => {
    if (imgRef.current?.src) {
      if (isBanner) {
        imgRef.current.src = bannerDefault
      } else if (isShop) {
        imgRef.current.src = shopDefault
      } else if(isVoucher) {
        imgRef.current.src = voucherDefault
      } else {
        imgRef.current.src = isAvatar ? avatarDefault : imageNotFound
      }

      setIsLoaded(false)
    }
  }

  return (
    <div className="flex h-full w-full justify-center overflow-hidden">
      <div
        className={`loader rounded-[50%] border-r-[2px] border-t-[2px] border-gray-700 ${
          isLoaded ? "opacity-1 h-[20px] w-[20px]" : "hidden"
        }`}
      />
      <img
        className={
          !isLoaded
            ? `opacity-1 ${getStyleImage} h-full`
            : "h-[0px] w-[0px] opacity-0"
        }
        ref={imgRef}
        alt={alt}
        src={url}
        onLoad={() => setIsLoaded(false)}
        onError={handleLoadError}
      />
    </div>
  )
}

export default ImgCustom
