import React, { useMemo } from "react"
import { TabItemAttributeType } from "../props"
import ImgCustom from "src/components/img-custom"
import "../index.scss"
import { getImageUrl } from "src/services/file-service"

export default function TabItem(props: TabItemAttributeType) {
  const { tabData, isCustomTab, selectedTabId, onTabClick } = props

  const handleTabClick = () => {
    if (onTabClick) onTabClick(tabData.id)
  }

  const isActive = useMemo(() => {
    return selectedTabId === tabData.id
  }, [tabData])

  return (
    <>
      {isCustomTab ? (
        <div
          className="flex w-[120px] cursor-pointer flex-col items-center justify-center gap-2 sm:w-[120px] md:w-[120px] lg:w-[160px]"
          onClick={handleTabClick}
        >
          <div
            className={`tab-item-container h-[52px] w-[52px] cursor-pointer rounded-full p-[10px]
            sm:h-[72px] sm:w-[72px] sm:p-[10px] md:h-[88px] md:w-[88px] md:p-[16px] lg:h-[88px] lg:w-[88px] lg:p-[16px] 
            ${isActive ? "is-active" : "normal"}`}
          >
            <ImgCustom
              className="w-full transform object-cover transition duration-[1500ms]"
              url={
                isActive
                  ? getImageUrl(tabData.selectedImg)
                  : getImageUrl(tabData.defaultImg)
              }
              alt="images"
            />
          </div>
          <div
            className={`break-words text-center text-center text-[14px] font-semibold uppercase lg:px-[10px] lg:text-[16px] lg:text-[16px] ${
              isActive ? " text-orange-dark-600" : "text-gray-500"
            }`}
          >
            {tabData.title}
          </div>
        </div>
      ) : (
        <div
          className="flex w-[120px] cursor-pointer flex-col items-center justify-center gap-2 sm:w-[120px] md:w-[188px] lg:w-[188px]"
          onClick={handleTabClick}
        >
          <ImgCustom
            className="w-full transform object-cover transition duration-[1500ms]"
            url={
              isActive
                ? getImageUrl(tabData.selectedImg)
                : getImageUrl(tabData.defaultImg)
            }
            alt="images"
          />
        </div>
      )}
    </>
  )
}
