import React, { useMemo } from "react"
import { VoucherBaseType } from "./type"
import { useTranslation } from "react-i18next"
import ImgCustom from "../img-custom"
import "./index.scss"

export default function VoucherBase(props: VoucherBaseType) {
  const {data} = props
  const {validTime, id, link, img, discount, minSpend} = data
  const { t } = useTranslation()
  const getTimeDisplay = useMemo(() => {
    const date = new Date(validTime)
    return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  }, [validTime])

  return (
    <div id="baseVoucher" className="flex bg-white rounded-3 gap-[20px]">
      <div className="w-[100px] h-[100px] flex items-center justify-center bg-orange-dark-500 rounded-3">
        <div className="w-[40px] h-[40px] rounded-3">
          <ImgCustom url={img} alt={`voucher-base-${id}`} className="rounded-3"/>
        </div>
      </div>
      <div className="flex p-[16px] gap-[4px] ">
        <div className="flex flex-col text-[14px] leading-[20px] text-gray-900 gap-[4px]">
          <span className="text-[18px] leading-[28px] font-semibold">{t("voucher-base.discount", {value: discount})}</span>
          <span>{t("voucher-base.min-spend", {value: minSpend})}</span>
          <span className="text-gray-500">{t("voucher-base.valid-time", {value: getTimeDisplay})}</span>
        </div>
        <a href={link} className="h-fit w-fit rounded-3 bg-orange-dark-500 text-white text-[14px] leading-[20px] font-medium px-[16px] py-[8px]">{t("voucher-base.view")}</a>
      </div>
    </div>
  )
} 
