import React, { useMemo } from "react"
import { VideoBaseType } from "./type"
import "./index.scss"

export default function VideoBase(props: VideoBaseType) {
  const {data} = props

  const getSource = useMemo(() => {
    let result = ""
    if(data.blocks[0].media[0].mobile_link) {
      result = data.blocks[0].media[0].mobile_link
    } else if(data.blocks[0].media[0].media_desktop_id) {
      result = `${process.env.REACT_APP_API_URL}/storage/files/stream-video/${data.blocks[0].media[0].media_desktop_id}.webm?quality=360`
    }
    return result
  }, [data])

  return (
    <div id="baseVideo" className="flex bg-white rounded-3 gap-[20px] w-full h-full">
      {
        data.blocks[0].media[0].mobile_link ? (
          <div className="h-[240px] w-full rounded-t-3">
            <iframe
              src={`${data.blocks[0].media[0].mobile_link}&mute=1`}
              frameBorder="8px"
              // allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              className="h-full w-full rounded-t-3"
            />
          </div> 
        ): null
      }
      {
        data.blocks[0].media[0].media_desktop_id ? (
          <div className="w-full h-full">
            <video
              src={getSource}
              muted
              className="h-full max-h-[300px] w-full object-cover"
              autoPlay={true}
            >
              <source src={getSource} type="webm" />
            </video>
          </div>
        ): null
      }
    </div>
  )
} 
