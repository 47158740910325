import React, { useMemo, useEffect } from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import { Outlet, useLocation } from "react-router-dom"
export type { MainLayoutProps }
import { ROUTERS, ROUTERS_NOT_LAYOUT } from "src/constants/router"
import { validateAndFetchCurrency } from "src/utils/convert-currency"
import HeaderView from "../header-view"
import FooterView from "../footer-view"

export default function MainLayout() {
  const location = useLocation()
  useEffect(() => {
    handleScrollToTop()
  }, [location?.pathname])

  // validate currency when change page
  useEffect(() => {
    validateAndFetchCurrency()
  }, [location.pathname])

  const isNotLayout = useMemo(() => {
    return (
      ROUTERS_NOT_LAYOUT.includes(location.pathname) ||
      location.pathname.includes(ROUTERS.LIVE_STREAM_ROOM_PATH) ||
      location.pathname.includes(ROUTERS.APPROVAL_REQUEST?.split("/")?.[1])
    )
  }, [location])

  const handleScrollToTop = () => {
    document
      .getElementById("content-body")
      ?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  if (isNotLayout) return <Outlet />

  return (
    <div
      className="no-scrollbar mx-auto flex h-full min-h-screen w-screen flex-col overflow-auto"
      id="content-body"
    >
      {/* HEADER VIEW */}
      <HeaderView />
      <main className="mt-[64px] flex-1 flex w-full flex-col justify-center gap-1 bg-gray-50">
        <div className="main mx-auto w-full max-w-[960px]">
          <Outlet />
        </div>
      </main>
      {/* FOOTER VIEW */}
      <FooterView />
    </div>
  )
}
