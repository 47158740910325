import React, { useEffect, useState } from "react"
import Loading from "../loading"
import ProductListSection from "."
import { Product } from "./props"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPELINK } from "src/constants/common"
import { getImageUrl } from "src/services/file-service"
import { MediaType } from "./enum"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { ProductSectionType } from "src/pages/preview/type"

export default function ProductSession(props: ProductSectionType) {
  const {
    items_per_line = 4,
    row = 1,
    filter = "",
    sort = "",
    systemDiscount,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const [products, setProducts] = useState<Array<Product>>([])
  const [productPage, setProductPage] = useState<number>(0)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const { t } = useTranslation()

  const handleGetDiscount = (originalPrice: number, price: number) => {
    if (Math.floor(100 - (price * 100) / originalPrice) > 1)
      return Math.floor(100 - (price * 100) / originalPrice + 0.5)
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapperProductData = (responseData: Array<any>) => {
    const products: Array<Product> = responseData.map((item) => {
      return {
        id: item.id,
        name: item.name,
        price: item.price_after_tax,
        currency: item.currency,
        imageUrl: item.avatar?.id ? getImageUrl(item.avatar?.id) : undefined,
        videoId: item.avatar?.id,
        discount: handleGetDiscount(
          item.original_price_after_tax,
          item.price_after_tax
        ),
        mediaType:
          item.avatar?.type === TYPELINK.LINK
            ? MediaType.VIDEO
            : MediaType.IMAGE,
        isFlashDeal: item.is_flash_deal,
      }
    })
    return products
  }

  const handleGetProducts = async () => {
    setIsLoading(true)
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchMarketingHubGet(
        filter,
        productPage,
        items_per_line * row,
        sort,
        {
          headers: {
            region: localRegion,
          },
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTotalProducts(res?.data?.data?.totalElement)
        const response = res?.data?.data?.data
        if (response) setProducts(mapperProductData(response))
      })
      .catch((e) => console.log(e.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleGetMoreProducts = async () => {
    if (products?.length < totalProducts) {
      const curPage = productPage + 1
      setProductPage(curPage)
      setIsLoadingMore(true)
      const localRegion = localStorage.getItem("localRegion") || ""
      await new StockProductControllerApi(configHeader(SERVICE.STOCK))
        .productsSearchMarketingHubGet(
          filter,
          curPage,
          items_per_line * row,
          sort,
          {
            headers: {
              region: localRegion,
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          const response = res?.data?.data?.data
          if (response)
            setProducts([...products, ...mapperProductData(response)])
        })
        .catch((e) => console.log(e.message))
        .finally(() => {
          setIsLoadingMore(false)
        })
    }
  }

  useEffect(() => {
    handleGetProducts()
  }, [])

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <ProductListSection
            products={products}
            rows={row}
            cols={items_per_line}
            systemDiscount={systemDiscount}
          />
          {!isLoadingMore ? (
            <div className="flex items-center justify-center">
              {products?.length < totalProducts ? (
                <div
                  onClick={handleGetMoreProducts}
                  className="cursor-pointer py-1 text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex justify-center py-4">
              <i
                className="pi pi-spin pi-spinner text-blue-500"
                style={{ fontSize: "1.5rem" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
