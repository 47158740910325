import React, { useMemo, useRef, useState } from "react"
import { Menu } from "primereact/menu"
import ImgCustom from "../img-custom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { isIFrame } from "../shareToken/types"
import { signOut } from "src/features/auth"
import LogoutModal from "../modal/logout-modal"
import CreateNewAccountModal from "../modal/create-new-account"
import LanguageModal from "../modal/language-modal"
import i18n from "src/locales"
import { ROUTER_PRIVATE } from "src/constants/router"
import { useNavigate } from "react-router-dom"

const AvatarView = () => {
  const [isShowSignOut, setIsShowSignOut] = useState<boolean>(false)
  const [isShowCreateNewAccount, setIsShowCreateNewAccount] = useState<boolean>(false)
  const [isShowLanguageModal, setIsShowLanguageModal] = useState<boolean>(false)
  const navigator = useNavigate()
  const authStore = useSelector((state: RootState) => state.auth)
  const userMenuRef = useRef<Menu>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const avatarImg = useMemo(() => {
    return authStore?.value ? authStore?.value.user?.avatar_thumbnail_url : ""
  }, [authStore])

  const username = useMemo(() => {
    return authStore?.value ? authStore?.value?.user?.username : ""
  }, [authStore])

  const handleSignOut = () => {
    setIsShowSignOut(false)
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "disconnect",
        data: {
          token: localStorage.getItem("accessToken"),
          user_id: localStorage.getItem("userId"),
        },
      }
      console.log("Message disconnect")
      frame.contentWindow.postMessage(messageData, "*")
    }
    localStorage.clear()
    dispatch(signOut())
    i18n.changeLanguage("en")
    const pathUrl = location.pathname
    let isNavigate = false
    ROUTER_PRIVATE.forEach(i => {
      if(pathUrl.includes(i)) isNavigate = true
    })
    if(isNavigate) navigator("/")
  }
  
  const handleCreateNewAccount = () => {
    setIsShowSignOut(false)
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "disconnect",
        data: {
          token: localStorage.getItem("accessToken"),
          user_id: localStorage.getItem("userId"),
        },
      }
      frame.contentWindow.postMessage(messageData, "*")
    }
    localStorage.clear()
    dispatch(signOut())
    setTimeout(() => {
      window.open(
        `${process.env.REACT_APP_HOME_DOMAIN_URL}/sign-up`,
        "_self"
      )
    }, 3000)
  }

  const handleSaveLanguage = () => {
    setIsShowSignOut(false)
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "disconnect",
        data: {
          token: localStorage.getItem("accessToken"),
          user_id: localStorage.getItem("userId"),
        },
      }
      frame.contentWindow.postMessage(messageData, "*")
    }
    localStorage.clear()
    dispatch(signOut())
    setTimeout(() => {
      window.open(
        `${process.env.REACT_APP_HOME_DOMAIN_URL}/sign-up`,
        "_self"
      )
    }, 200)
  }

  const subData = useMemo(() => {
    return [
      {
        type: "setting",
        label: capitalizedFirst(t("header.setting")),
        icon: "sctr-icon-settings-02",
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/account-settings`,
        key: `setting-${new Date().getTime}`,
      },
      {
        type: "help",
        label: capitalizedFirst(t("header.help")),
        icon: "sctr-icon-info-circle",
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/about-us`,
        key: `help-${new Date().getTime}`,
      },
    ]
  }, [i18n.language])

  const lngData = useMemo(() => {
    return [
      {
        value: "en",
        label: "English",
      },
      {
        value: "vi",
        label: "Tiếng Việt",
      },
    ]
  }, []) 

  const getLangDisplay = useMemo(() => {
    const result = lngData.find(i => i.value === i18n.language)    
    return result ? result.label : "English"

  }, [i18n.language])

  if (!authStore?.value) return null
  const items = [
    {
      template: () => {
        return (
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex gap-[12px] p-1 px-2">
                <div className="rounded-full border-[2px] border-orange-500">
                  <div
                    className="h-[48px] w-[48px] rounded-full border-[2px] border-white"
                    onClick={(e) => userMenuRef?.current?.toggle(e)}
                  >
                    <ImgCustom
                      isAvatar={true}
                      alt="userAvatar"
                      url={avatarImg as string}
                      className="h-full w-full rounded-full object-cover object-center"
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col justify-center">
                  <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                    {authStore.value?.user?.full_name}
                  </span>
                  <span className="text-[12px] leading-[16px] text-gray-500">
                    {`@${authStore.value?.user?.username}` || ""}
                  </span>
                </div>
              </div>
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_HOME_DOMAIN_URL}/personal-profile/${username}?Tab=Timeline`}
                className="mt-2 flex cursor-pointer items-center justify-center border rounded-[6px] border-blue-50 bg-blue-50 mx-[12px] px-2 py-[5px] text-[14px] font-semibold leading-[20px] text-blue-700 hover:opacity-70"
              >
                {capitalizedFirst(t("header-setting.view-profile"))}
              </a>
            </div>
            <div className="flex cursor-pointer mt-[12px] flex-col border-t-[1px] border-t-gray-300">
              {
                subData.map(i => (
                  <div key={i.key}>
                    {
                      i.type === "language" ? (
                        <div onClick={() => setIsShowLanguageModal(true)} className="cursor-pointer hover:bg-gray-200">
                          <div className="flex items-center justify-between p-[12px]">
                            <div className="flex items-center gap-[12px]">
                              <i className={`${i.icon}`}/>
                              <span className="text-[16px] leading-[24px] ">{i.label}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>{getLangDisplay}</span>
                              <i className="sctr-icon-chevron-right"/>
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      i.type !== "language" ? (
                        <a href={i.link} className="cursor-pointer hover:bg-gray-200">
                          <div className="flex items-center justify-between p-[12px]">
                            <div className="flex items-center gap-[12px]">
                              <i className={`${i.icon}`}/>
                              <span className="text-[16px] leading-[24px] ">{i.label}</span>
                            </div>
                            <i className="sctr-icon-chevron-right"/>
                          </div>
                        </a>
                      ) : null
                    }
                  </div>
                ))
              }
            </div>

            <div className="flex cursor-pointer flex-col border-t-[1px] border-t-gray-300">
              <div
                onClick={() => setIsShowCreateNewAccount(true)}
                className="flex items-center gap-1 px-2 py-[9px] text-[16px] leading-[24px] text-black hover:bg-blue-50"
              >
                <i className="sctr-icon-plus" />
                <span>
                  {capitalizedFirst(t("header-setting.create-new-account"))}
                </span>
              </div>
              <div
                onClick={() => setIsShowSignOut(true)}
                className="flex items-center gap-1 px-2 py-[9px] text-[16px] leading-[24px] text-red-600 hover:bg-blue-50"
              >
                <i className="sctr-icon-log-out-03" />
                <span>{capitalizedFirst(t("header-setting.sign-out"))}</span>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div
        className="h-[36px] w-[36px] rounded-full border-[2px] border-white"
        onClick={(e) => userMenuRef?.current?.toggle(e)}
      >
        <ImgCustom
          isAvatar={true}
          alt="userAvatar"
          url={(authStore.value.user?.avatar_thumbnail_url as string) || ""}
          className="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <Menu model={items} popup ref={userMenuRef} className="mt-1 w-[300px]" />
      {isShowSignOut && (
        <LogoutModal
          isShow={isShowSignOut}
          title={capitalizedFirst(t("header.sign-out-modal"))}
          message=""
          handleClose={() => setIsShowSignOut(false)}
          handleConfirm={handleSignOut}
        />
      )}
      {isShowCreateNewAccount && (
        <CreateNewAccountModal
          isShow={isShowCreateNewAccount}
          title={capitalizedFirst(t("header.create-new-account"))}
          message={capitalizedFirst(t("header.create-new-account-detail"))}
          handleClose={() => setIsShowCreateNewAccount(false)}
          handleConfirm={handleCreateNewAccount}
        />
      )}
      {isShowLanguageModal && (
        <LanguageModal
          isShow={isShowLanguageModal}
          title={capitalizedFirst(t("header.language-data.title"))}
          message={""}
          handleClose={() => setIsShowLanguageModal(false)}
          handleConfirm={handleSaveLanguage}
        />
      )}
    </>
  )
}

export default AvatarView
