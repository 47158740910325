import "./index.scss"
import React, { useMemo, useRef, useState } from "react"
import { ImgBuyerSideProps } from "./props"
import bannerDefault from "src/assets/images/bg.jpg"
import { getImageUrl } from "src/services/file-service"

const ImgBuyerSide = (props: ImgBuyerSideProps) => {
  const { alt, className, url, mobileUrl, isMobile } = props
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const imgRef = useRef<HTMLImageElement>(null)
  const getStyleImage = useMemo(() => {
    return className
      ? `object-cover object-center w-full ${className}`
      : "object-cover object-center w-full"
  }, [className])

  const handleLoadError = () => {
    if (imgRef.current?.src) {
      imgRef.current.src = bannerDefault
    }
    setIsLoaded(false)
  }

  return (
    <div className="flex h-full items-center justify-center overflow-hidden">
      <div
        className={`loader rounded-[50%] border-r-[2px] border-t-[2px] border-gray-700 
          ${isLoaded ? "opacity-1 z-50 h-[20px] w-[20px]" : "hidden"}`}
      />
      <img
        className={
          !isLoaded
            ? `opacity-1 w-full ${getStyleImage}`
            : "h-[0px] w-[0px] opacity-0"
        }
        ref={imgRef}
        alt={alt}
        src={isMobile && mobileUrl ? getImageUrl(mobileUrl) : getImageUrl(url)}
        onLoad={() => setIsLoaded(false)}
        onError={handleLoadError}
      />
    </div>
  )
}
export default ImgBuyerSide
