import React from "react"
import "./index.scss"
import { ProductList } from "./props"
import ProductCard from "./components/ProductCard"
import EmptyPage from "../../pages/empty-page"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function ProductListSection(props: ProductList) {
  const { products = [], cols = 4 } = props
  const { t } = useTranslation()

  const handleAddCart = () => {
    console.log("handle add cart")
  }

  const getColNumClass = () => {
    const COL_CLASS_MAP: { [key: number]: string } = {
      2: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2",
      3: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3",
      4: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4",
      5: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5",
      6: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6",
      7: "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 xl:grid-cols-7",
    }
    return (
      COL_CLASS_MAP[cols] ||
      "grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4"
    )
  }

  return (
    <div className="flex h-full w-full flex-col items-center gap-[12px]">
      {/* <Banner title={title} /> */}
      {!products.length ? (
        <EmptyPage
          message={capitalizedFirst(t("global.data-not-found"))}
          className="mt-3 !bg-transparent"
        />
      ) : (
        <div className={getColNumClass()}>
          {products.map((item, index) => (
            <ProductCard
              key={index}
              {...item}
              systemDiscount={props.systemDiscount}
              onAddCart={handleAddCart}
            />
          ))}
        </div>
      )}
    </div>
  )
}
