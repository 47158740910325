import { MediaType } from "../../components/product-list/enum"
import { Product } from "../../components/product-list/props"
import tab1 from "src/assets/images/tab1.svg"
import tab2 from "src/assets/images/tab2.svg"
import tab3 from "src/assets/images/tab3.svg"
import tab4 from "src/assets/images/tab4.svg"
import tab5 from "src/assets/images/tab5.svg"
import tabActive from "src/assets/images/tab1-active.svg"
import dummyBanner from "src/assets/images/dummy-banner.png"

import { ImgBuyerSideProps } from "src/components/img-buyer-side-custom/props"
import { TabItemAttributeType } from "../../components/tab-list/props"

export const PRODUCT_LIST: Array<Product> = [
  {
    id: "113425facgeg-2fafc-gvss-1100",
    name: "Polo Pique Basic Cotton 100% Ready To Wear",
    price: 39.9,
    currency: "USD",
    imageUrl:
      "https://api.soctrip.com/storage/files/web/vn_20240623T125111383899480_0302bc21-486d-4003-9143-1a3ea36d5b6a_vn111342077r98olngtmut73q7u0e.webp",
    mediaType: MediaType.IMAGE,
    discount: 50,
    isFlashDeal: true,
    systemDiscount: {
      discountPercent: 50,
    },
  },
  {
    id: "113425facgeg-2fafc-gvss-1100",
    name: "Polo Pique Basic Cotton 100% Ready To Wear",
    price: 39.9,
    currency: "USD",
    imageUrl:
      "https://api.soctrip.com/storage/files/web/vn_20240623T125111383899480_0302bc21-486d-4003-9143-1a3ea36d5b6a_vn111342077r98olngtmut73q7u0e2.webp",
    discount: 45,
    mediaType: MediaType.IMAGE,
    isFlashDeal: true,
    systemDiscount: {
      discountPercent: 20,
    },
  },
  {
    id: "113425facgeg-2fafc-gvss-1100",
    name: "Polo Pique Basic Cotton 100% Ready To Wear",
    price: 39.9,
    currency: "USD",
    imageUrl:
      "https://api.stagingsoctrip.com/storage/files/web/vn_20240523T233816877353553_959af87d-6f69-46b4-9d1a-be1c071267f2_sg1113420123010ivhbolfayimv5e.webp",
    discount: undefined,
    mediaType: MediaType.IMAGE,
    isFlashDeal: false,
    systemDiscount: undefined,
  },
  {
    id: "113425facgeg-2fafc-gvss-1101",
    name: "Polo Pique Basic Cotton 100% Ready To Wear",
    price: 39.9,
    currency: "USD",
    imageUrl:
      "https://api.stagingsoctrip.com/storage/files/web/vn_20240523T233816877353553_959af87d-6f69-46b4-9d1a-be1c071267f2_sg1113420123010ivhbolfayimv5e.webp",
    discount: undefined,
    mediaType: MediaType.IMAGE,
    isFlashDeal: true,
    systemDiscount: {
      discountPercent: 50,
    },
  },
  {
    id: "113425facgeg-2fafc-gvss-1102",
    name: "Polo Pique Basic Cotton 100% Ready To Wear",
    price: 39.9,
    currency: "USD",
    imageUrl:
      "https://api.stagingsoctrip.com/storage/files/web/vn_20240523T233816877353553_959af87d-6f69-46b4-9d1a-be1c071267f2_sg1113420123010ivhbolfayimv5e.webp",
    discount: undefined,
    mediaType: MediaType.IMAGE,
    isFlashDeal: true,
    systemDiscount: {
      discountAmount: 20,
    },
  },
]

export const TAB_LIST: Array<TabItemAttributeType> = [
  {
    tabData: {
      id: "1",
      title: "CHƯƠNG TRÌNH KHUYẾN MÃI",
      defaultImg: tab1,
      selectedImg: tabActive,
    },
  },
  {
    tabData: {
      id: "2",
      title: "DEAL HOT HÔM NAY",
      defaultImg: tab2,
      selectedImg: tabActive,
    },
  },
  {
    tabData: {
      id: "3",
      title: "ƯU ĐÃI TỪ THƯƠNG HIỆU",
      defaultImg: tab3,
      selectedImg: tabActive,
    },
  },
  {
    tabData: {
      id: "4",
      title: "KHUYẾN MÃI NHÂN ĐÔI",
      defaultImg: tab4,
      selectedImg: tabActive,
    },
  },
  {
    tabData: {
      id: "5",
      title: "MUA NHANH GIẢM SÂU",
      defaultImg: tab5,
      selectedImg: tabActive,
    },
  },
  {
    tabData: {
      id: "6",
      title: "Tức thời",
      defaultImg: tab3,
      selectedImg: tabActive,
    },
  },
  // {
  //   id: "7",
  //   title: "MUA NHANH GIẢM SÂU",
  //   defaultImg: tab5,
  //   selectedImg: tabActive,
  // },
  // {
  //   id: "8",
  //   title: "MUA NHANH GIẢM SÂU",
  //   defaultImg: tab5,
  //   selectedImg: tabActive,
  // },
  // {
  //   id: "9",
  //   title: "MUA NHANH GIẢM SÂU",
  //   defaultImg: tab5,
  //   selectedImg: tabActive,
  // },
]

export const voucherDataDummy = [
  {
    img: "",
    id: "123",
    link: "",
    minSpend: 10,
    validTime: new Date().getTime(),
    discount: "10%",
  },
  {
    img: "",
    id: "1234",
    link: "",
    minSpend: 10,
    validTime: new Date().getTime(),
    discount: "10%",
  },
  {
    img: "",
    id: "1235",
    link: "",
    minSpend: 10,
    validTime: new Date().getTime(),
    discount: "10%",
  },
  {
    img: "",
    id: "1236",
    link: "",
    minSpend: 10,
    validTime: new Date().getTime(),
    discount: "10%",
  },
]

export const IMAGE_DUMMY: ImgBuyerSideProps[] = [
  {
    url: dummyBanner,
    isMobile: false,
  },
  {
    url: "",
    isMobile: true,
  },
]

export const TEXT_SECTION_DATA = `
  <p><strong>11.11 Super Shopping Day - Free Shipping Nationwide 😀</strong></p>
  <p><br></p>
  <p>Soctrip - a trusted platform that brings you an enjoyable, fast, and secure online shopping experience while saving money. In September, Soctrip will launch a series of deals and promotions that will amaze you. Discover the 9.9 Super Shopping Day event with exclusive deals only at Soctrip. Enjoy a variety of hot deals, vouchers, and special discounts. Start shopping at Soctrip 9.9 today!</p>
  <p><br></p>
  <p>The 9.9 Super Shopping Day Sale: Soctrip’s 9.9 sale officially kicks off from 26/08/2024 and ends on 11/09/2024. During this period, Soctrip brings a wave of irresistible 
  <a href="https://shop.soctrip.com/shopping" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 172, 210);">shopping deals</a>, 
  <a href="https://jpuri.github.io/react-draft-wysiwyg/shopping" rel="noopener noreferrer" target="_blank" style="color: rgb(26, 188, 156);">vouchers</a>, 
  and discounts that you can’t miss, such as Voucher Xtra with extra discounts. Enjoy an exceptional shopping experience with the following exclusive deals from Soctrip 9.9:</p>
  <p><br></p>
  <ul>
    <li>Check out Soctrip Video and get a 50% discount voucher: Watch Soctrip Video and grab a 50% discount!</li>
    <li>Free nationwide shipping for orders over 50k: Shop Soctrip’s hottest deals and save even more. Grab your deals today during the 9.9 Soctrip sale!</li>
    <li>Chance to win 99 iPhone super prizes: Take advantage of special deals and don’t miss your chance to win one of the 99 exciting iPhone prizes. Join now!</li>
  </ul>
  <p><br></p>`

export const TEXT_SECTION_DATA_2 = `
  <p><strong>11.11 Super Shopping Day - Free Shipping Nationwide 😀</strong></p><p><br></p><p>
  Soctrip - a trusted platform that brings you an enjoyable, fast, and secure online shopping experience while saving money. In September, Soctrip will launch a series of deals and promotions that will amaze you. Discover the 9.9 Super Shopping Day event with exclusive deals only at Soctrip. Enjoy a variety of hot deals, vouchers, and special discounts. Start shopping at Soctrip 9.9 today! </p><p><br></p><p>The 9.9 Super Shopping Day Sale: Soctrip’s 9.9 sale officially kicks off from 26/08/2024 and ends on 11/09/2024. During this period, Soctrip brings a wave of irresistible <a href="https://shop.soctrip.com/shopping" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 172, 210);">shopping deals</a> ,
  <a href="https://jpuri.github.io/react-draft-wysiwyg/shopping" rel="noopener noreferrer" target="_blank" style="color: rgb(26, 188, 156);">vouchers</a> , and discounts that you can’t miss, such as Voucher Xtra with extra discounts. Enjoy an exceptional shopping experience with the following exclusive deals from Soctrip 9.9: </p><p><br></p><ol><li>Check out Soctrip Video and get a 50% discount voucher: Watch Soctrip Video and grab a 50% discount! </li><li>Free nationwide shipping for orders over 50k: Shop Soctrip’s hottest deals and save even more. Grab your deals today during the 9.9 Soctrip sale! </li><li>Chance to win 99 iPhone super prizes: Take advantage of special deals and don’t miss your chance to win one of the 99 exciting iPhone prizes. Join now! </li></ol><p><br></p>`

// export const BANNER_SLIDER_DUMMY: BannerItemType[] = [
//   {
//     id: "1",
//     desktopUrl: dummyBanner,
//     navigateUrl: "https://tmtrav-dev.tma-swerp.com/",
//     isOpenNewTab: false,
//   },
//   {
//     id: "2",
//     desktopUrl: dummyBanner2,
//     navigateUrl: "https://tmtrav-dev.tma-swerp.com/",
//     isOpenNewTab: false,
//   },
//   {
//     id: "3",
//     desktopUrl: dummyBanner,
//     mobileUrl: dummyBanner,
//     navigateUrl: "https://tmtrav-dev.tma-swerp.com/",
//     isOpenNewTab: true,
//   },
// ]
