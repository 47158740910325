import { SystemDiscount } from "src/components/product-list/props"

export interface CampaignInfoType {
  id: string
  title: string
}

export interface LayerType {
  id: string
  code: string
  description: string
  blocks: BlockType[]
}
export interface BlockType {
  code: string
  description: string
  text: string
  media: MediaType[]
  line: number
  items_per_line: number
  reference_id: string
  row?: number
  filter: string
  sort: string
}

export interface ProductSectionType extends BlockType {
  systemDiscount?: SystemDiscount
}

export interface MediaType {
  desktop_link: string
  desktop_name: string
  is_open_in_new_tab: boolean
  media_desktop_id: string
  media_mobile_id: string
  mobile_link: string
  mobile_name: string
}

export enum BLOCK_TYPE {
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  SLIDE = "SLIDE",
  VIDEO = "VIDEO",
  TAB = "TAB",
  PRODUCT_LIST = "PRODUCT_LIST",
  VOUCHER = "VOUCHER",
}
